import { Spinner } from '@upper/ui'
import Head from 'next/head'
import Link from 'next/link'
import { useQuery } from 'urql'
import {
  JobsDashboardDocument,
  JobStatus,
  EarningsDocument,
} from '@upper/graphql/fellow'
import { useRouter } from 'next/router'
import MainLayout from '../components/main-layout'
import { useAuth } from '@upper/auth'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { calculateEarningsStats } from '../lib/earnings'
import { formatCurrency } from '../lib/earnings'

export function Index() {
  const { user } = useAuth()

  const [jobsDashboardResult] = useQuery({
    query: JobsDashboardDocument,
  })

  const [earningsResult] = useQuery({
    query: EarningsDocument,
  })

  if (jobsDashboardResult.fetching || earningsResult.fetching) {
    return (
      <div className="flex items-center justify-center h-80">
        <Spinner />
      </div>
    )
  }

  if (jobsDashboardResult.error) {
    return <div>Error: {jobsDashboardResult.error.message}</div>
  }

  if (earningsResult.error) {
    return <div>Error: {earningsResult.error.message}</div>
  }

  const earningsStats = calculateEarningsStats(earningsResult.data!.invoices!)

  const publishedJobs = jobsDashboardResult.data?.jobs.filter(
    (job) => job.status !== JobStatus.Draft
  )

  const draftJobs = jobsDashboardResult.data?.jobs.filter(
    (job) => job.status === JobStatus.Draft
  )

  return (
    <div>
      <Head>
        <title>Dashboard - Upper Fellow</title>
      </Head>

      <div>
        <h1 className="text-2xl font-semibold leading-tight md:text-3xl md:mt-16">
          Hello, {user?.firstName}! 👋
        </h1>

        {draftJobs && draftJobs?.length > 0 && (
          <div className="mt-10 bg-white rounded-lg p-4 border-gray-light border-4">
            <h3 className="text-2xl font-bold">Drafts</h3>
            <ul className="mt-6 space-y-6">
              {draftJobs.map((draftJob) => (
                <li key={draftJob.id}>
                  <Link href={`/projects/${draftJob.project?.id}/draft`}>
                    <a>
                      <h4 className="font-bold">{draftJob.name}</h4>
                      <p className="text-xs font-medium text-gray">
                        saved{' '}
                        {formatDistanceToNow(parseISO(draftJob.updatedAt))} ago
                      </p>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="mt-10 grid gap-10 max-w-4xl p-4 bg-white rounded-lg shadow-sm md:grid-cols-2">
          <div>
            <h3 className="font-bold">Jobs</h3>
            <dl className="grid grid-cols-2 gap-10 mt-4">
              <div>
                <dl className="text-gray font-medium text-xs">Published</dl>
                <dt className="mt-1 text-2xl font-bold text-blue leading-none">
                  {publishedJobs?.length}
                </dt>
              </div>
              <div>
                <dl className="text-gray font-medium text-xs">Draft</dl>
                <dt className="mt-1 text-2xl font-bold text-blue leading-none">
                  {draftJobs?.length}
                </dt>
              </div>
            </dl>
          </div>
          <div>
            <h3 className="font-bold">Earnings</h3>
            <dl className="grid grid-cols-2 gap-10 mt-4">
              <div>
                <dl className="text-gray font-medium text-xs">Paid</dl>
                <dt className="mt-1 text-2xl font-bold text-green leading-none">
                  {formatCurrency(earningsStats.paid)}
                </dt>
              </div>
              <div>
                <dl className="text-gray font-medium text-xs">Open</dl>
                <dt className="mt-1 text-2xl font-bold text-gray-dark leading-none">
                  {formatCurrency(earningsStats.open)}
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index

Index.getLayout = (page: React.ReactElement) => <MainLayout>{page}</MainLayout>

Index.authenticate = true
