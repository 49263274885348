import { InvoiceFragment } from '@upper/graphql/fellow'

export function calculateEarningsStats(invoices: InvoiceFragment[]) {
  return invoices.reduce(
    (acc, invoice) => {
      if (invoice.status === 'Voided') {
        return acc
      }

      if (invoice.items?.[0].fellowStatus === 'Paid') {
        acc.paid = acc.paid + invoice.fellowTotalNet!
      } else {
        acc.open = acc.open + invoice.fellowTotalNet!
      }

      acc.total = acc.total + invoice.fellowTotalNet!

      return acc
    },
    { paid: 0, open: 0, total: 0 }
  )
}

export function formatCurrency(number: number) {
  const formattedNumber = Intl.NumberFormat('en-DE', {
    maximumFractionDigits: 2,
  }).format(number)
  return `€${formattedNumber}`
}
